import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";

const FormField = ({
  field,
  previewMode,
  onDelete,
  onUpdateLabel,
  onUpdatePlaceholder,
  onUpdateOptions,
}) => {
  const [newOption, setNewOption] = useState("");
  const [editingOptionIndex, setEditingOptionIndex] = useState(null);
  const [editingOptionValue, setEditingOptionValue] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  const handleAddOption = () => {
    if (newOption.trim()) {
      onUpdateOptions([...field.options, newOption]);
      setNewOption("");
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleEditOption = (index) => {
    setEditingOptionIndex(index);
    setEditingOptionValue(field.options[index]);
  };

  const handleSaveEditedOption = () => {
    const updatedOptions = [...field.options];
    updatedOptions[editingOptionIndex] = editingOptionValue;
    onUpdateOptions(updatedOptions);
    setEditingOptionIndex(null);
    setEditingOptionValue("");
  };

  const renderInput = () => {
    const inputStyle = { height: '30px', fontSize: '0.85rem' }; 
    switch (field.field_type) {
      case "text":
        return <Input placeholder={field.placeholder} style={inputStyle} />;
      case "email":
        return <Input placeholder={field.placeholder} style={inputStyle} />;
      case "number":
        return (
          <InputNumber
            placeholder={field.placeholder}
            style={{
              ...inputStyle,
              width: "100%",
            }}
          />
        );
      case "password":
        return <Input.Password placeholder={field.placeholder} style={inputStyle} />;
      case "checkbox":
        return (
          <Checkbox.Group>
            {field.options.map((option, index) => (
              <Checkbox value={option} key={index}>{option}</Checkbox>
            ))}
          </Checkbox.Group>
        );
      case "radio":
        return (
          <Radio.Group>
            {field.options.map((option, index) => (
              <Radio value={option} key={index}>{option}</Radio>
            ))}
          </Radio.Group>
        );
      case "dropdown":
        return (
          <Select
            placeholder={field.placeholder}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={field.options.map((option) => ({
              value: option,
              label: option,
            }))}
            style={inputStyle}
          />
        );
      case "date":
        return <DatePicker style={inputStyle} />;
      case "textarea":
        return <TextArea rows={3} placeholder={field.placeholder} style={inputStyle} />;
      case "file":
        return (
          <Upload action="/upload.do" listType="picture-card" maxCount={1}>
            <button
              style={{
                border: 0,
                background: "none",
              }}
              type="button"
            >
              <PlusOutlined />
              <div style={{ marginTop: 5 }}>Upload</div>
            </button>
          </Upload>
        );
      case "button":
        return (
          <Button type="primary" htmlType="submit" style={{marginLeft:'0%', backgroundColor:"#6882ff",textTransform:"capitalize",  padding: "2px 12px",
            fontSize: "12px",
            height: "28px",
            minWidth: "60px",
            lineHeight: "1.5",}}>
            {field.placeholder}
          </Button>
        );
      default:
        return <div>Unknown Form Item</div>;
    }
  };

  return (
    <div
      style={{
        marginTop:"10px",
        marginBottom: "24px",
        borderRadius: "12px",
        backgroundColor: "white",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        transition: "all 0.3s ease",
        border: "1px solid rgba(233, 236, 239, 0.8)",
        overflow: "hidden",
        position: "relative",
        padding: "16px",
      }} 
      onMouseEnter={(e) => {
        if (!previewMode) {
          e.currentTarget.style.boxShadow = "0 12px 24px rgba(0,0,0,0.15)";
          e.currentTarget.style.transform = "translateY(-2px)";
          e.currentTarget.style.borderColor = "rgba(80, 70, 229, 0.3)";
        }
      }}
      onMouseLeave={(e) => {
        if (!previewMode) {
          e.currentTarget.style.boxShadow = "0 4px 12px rgba(0,0,0,0.1)";
          e.currentTarget.style.transform = "translateY(0)";
          e.currentTarget.style.borderColor = "rgba(233, 236, 239, 0.8)";
        }
      }}
    >
      <div
        className="form-field-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Form.Item 
          label={field.label}
          name={field.label}
          style={{ 
            margin: 0,
            flex: 1,
            color: "#dc3545", fontSize: "10px" 
          }}
        >
          {renderInput()}
        </Form.Item>
        
        {!previewMode && (
          <div className="form-field-actions" style={{ marginLeft: "16px" }}>
            <button
              onClick={() => setShowDetails(!showDetails)}
              style={{
                marginRight: "8px",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ color: "rgb(80, 70, 229)" }}
              />
            </button>
            <button
              onClick={onDelete}
              style={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ color: "#dc3545" }} />
            </button>
          </div>
        )}
      </div>

      {!previewMode && showDetails && (
        <div className="form-field-details" style={{ marginTop: "16px", position: "sticky", top: "0", backgroundColor: "#fff", zIndex: 1, padding: "16px", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}>
          <label style={{ fontWeight: "500", marginBottom: "4px", fontSize: "10px" }}>Label</label>
          <Input
            type="text"
            value={field.label}
            onChange={(e) => onUpdateLabel(e.target.value)}
            placeholder="Label"
            style={{ marginBottom: "8px", width: "100%", height: '30px', fontSize: '0.85rem' }} 
            onFocus={(e) => e.target.scrollIntoView({ behavior: "smooth", block: "center" })}
            onClick={(e) => e.stopPropagation()}
          />
          <label style={{ fontWeight: "500", marginBottom: "4px", fontSize: "10px" }}>Placeholder</label> 
          <Input
            type="text"
            value={field.placeholder}
            onChange={(e) => onUpdatePlaceholder(e.target.value)}
            placeholder="Placeholder"
            style={{ marginBottom: "8px", width: "100%", height: '30px', fontSize: '0.85rem' }} 
            onFocus={(e) => e.target.scrollIntoView({ behavior: "smooth", block: "center" })}
            onClick={(e) => e.stopPropagation()}
          />
          {["radio", "checkbox", "dropdown"].includes(field.field_type) && (
            <div>
              {field.options.map((option, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  {editingOptionIndex === index ? (
                    <Input
                      type="text"
                      value={editingOptionValue}
                      onChange={(e) => setEditingOptionValue(e.target.value)}
                      style={{ marginRight: "8px", flexGrow: 1, height: '30px', fontSize: '0.85rem' }} 
                      onFocus={(e) => e.target.scrollIntoView({ behavior: "smooth", block: "center" })}
                      onClick={(e) => e.stopPropagation()}
                    />
                  ) : (
                    <span style={{ flexGrow: 1 }}>{option}</span>
                  )}
                  {editingOptionIndex === index ? (
                    <button
                      onClick={handleSaveEditedOption}
                      style={{
                        marginLeft: "8px",
                        padding: "4px 8px",
                        backgroundColor: "#28a745",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleEditOption(index)}
                      style={{
                        marginLeft: "8px",
                        padding: "4px 8px",
                        backgroundColor: "#007bff",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  )}
                </div>
              ))}
              <Input
                type="text"
                value={newOption}
                onChange={(e) => setNewOption(e.target.value)}
                placeholder="Add option"
                style={{ marginBottom: "8px", width: "100%", height: '30px', fontSize: '0.85rem' }} 
                onFocus={(e) => e.target.scrollIntoView({ behavior: "smooth", block: "center" })}
                onClick={(e) => e.stopPropagation()}
              />
              <button
                onClick={handleAddOption}
                style={{
                  marginBottom: "8px",
                  width: "100%",
                  padding: "8px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Add Option
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FormField;