import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";
import { parseISO } from 'date-fns';
import { parse, startOfDay, endOfDay } from 'date-fns';
import moment from 'moment';
import {
  faShareAlt,
  faCopy,
  faTrash,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  Button,
  Checkbox,
  DatePicker,
  TimePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Upload,
  Model,
  Icon,
  Tag,
  message,
  Modal,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { CopyOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined, ShareAltOutlined, UploadOutlined } from "@ant-design/icons";
import BreadCrumbs from "../BreadCrumbs";
import styled from "@emotion/styled";
import {
  URL_SurveyBroadcastAPIV2,
  URL_SurveyFormAdminAPIV2,
} from "../../util/constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';

const ViewForms = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const [forms, setForms] = useState([]);
  const [surveyData, setSurveyData] = useState([]); 
  const [editorValue, setEditorValue] = useState("");
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [formURL, setFormURL] = useState("http://dummyLink/view-forms");
  const [shareDisable, setShareDisable] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
const [selectedTime, setSelectedTime] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const openMessage = () => {
    messageApi.open({
      key,
      type: "loading",
      content: "Copying...",
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: "success",
        content: "Copied!",
        duration: 2,
      });
    }, 1000);
  };

  // const handleSearch = (value) => {
  //   const newEmail = value.trim();
  //   if (newEmail) {

  //     setEmails([...emails, newEmail]);
  //   }
  // };

  const handleDateChange = (dates) => {
    console.log("DATesssssssssssss", dates);
    setDateRange(dates);
  };
  
  const safeSurveyData = Array.isArray(surveyData) ? surveyData : [];
  
  const parseCustomDateFormat = (dateString) => {
    // Parse date in format "DD/MM/YYYY, HH:MM:SS"
    return parse(dateString, 'dd/MM/yyyy, HH:mm:ss', new Date());
  };
  
  const filteredSurveyData = safeSurveyData.filter((item) => {
    const matchesSearch = item.title?.toLowerCase().includes(searchInput?.toLowerCase());
    
    // If no date range is selected or it's incomplete, just filter by search
    if (!dateRange || dateRange.length !== 2) return matchesSearch;
    
    // Parse dates correctly from strings with specific format
    const createdAt = parseCustomDateFormat(item.createdAt);
    const lastSubmitted = parseCustomDateFormat(item.lastSubmitted);
    
    // Get start and end dates from the dateRange
    let startDate, endDate;
    
    if (typeof dateRange[0] === 'string') {
      // If dateRange contains ISO strings
      startDate = startOfDay(new Date(dateRange[0]));
      endDate = endOfDay(new Date(dateRange[1]));
    } else if (dateRange[0] instanceof Date) {
      // If dateRange already contains Date objects
      startDate = startOfDay(dateRange[0]);
      endDate = endOfDay(dateRange[1]);
    } else {
      // If dateRange has objects with toDate() method (like Moment.js or Day.js)
      startDate = startOfDay(dateRange[0].toDate());
      endDate = endOfDay(dateRange[1].toDate());
    }
    
    return (
      matchesSearch &&
      ((createdAt >= startDate && createdAt <= endDate) ||
        (lastSubmitted >= startDate && lastSubmitted <= endDate))
    );
  });

  const { RangePicker } = DatePicker;  

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
              <span
              onClick={() => {
                handleChange(record.id);
                showModalForm();
              }}
                style={{
                  color: "#1890ff",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontSize: "12px",
                }}
              >
                {text}
              </span>
            ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Last Submitted",
      dataIndex: "lastSubmitted",
      key: "lastSubmitted",
    },
    // {
    //   title: "View",
    //   key: "view",
    //   render: (_, record) => (
    //     <Button
    //       onClick={() => {
    //         handleChange(record.id);
    //         showModalForm();
    //       }}
    //     >
    //       View
    //     </Button>
    //   ),
    // },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div>
          <Button
            icon={<ShareAltOutlined style={{ color: "#468cfe" }} />}
            onClick={() => {
              handleChange(record.id);
              
              handleViewClick(record.title);
            }}
            style={{ marginRight: '8px', border: 'none' }}
          />
          <Button
            icon={<CopyOutlined style={{ color: "#18bd0b" }} />}
            onClick={() => {
              handleCloneForm(record);
            }}
            style={{ marginRight: '8px', border: 'none' }}
          />
        {record.lastSubmitted === null || record.lastSubmitted === "" || record.lastSubmitted === undefined ? (
  <Button
    icon={<EditOutlined style={{ color: "#faad14" }} />}
    onClick={async () => {
      console.log("🟢 Record Before Navigation:", record);
      if (!record.form_schema || record.form_schema.length === 0) {
        console.error("❌ form_schema is empty or missing before navigation:", record);
        
      }
      handleChange(record.id);
      let formData=[];
      try {
        const url = `${URL_SurveyFormAdminAPIV2}/dev/survey-formV2?id=${record.id}`;
       await axios
          .get(url) // Use params object for GET requests
          .then((response) => {
            const updatedFormSchema = response.data[0].form_schema.map(
                    (field) => ({
                      ...field,
                      id: uuidv4(),
                    })
                  );
            formData=updatedFormSchema
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error fetching survey form:", error);
        throw error; // Re-throw the error to handle it appropriately in the calling component
      }
    console.log("record.form_schema:",formData)
      
      navigate("/form-builder", { 
        state: { 
          formData: {
            ...record,
            form_schema: formData
          } 
        } 
      });
    }}
    style={{ marginRight: '8px', border: 'none' }}
  />
) : null}
 
          <Button
            icon={<DeleteOutlined style={{ color: "#ff4d4f" }} />}
            onClick={() => {
              handleDeleteClick(record.id);
            }}
            style={{ marginRight: '8px', border: 'none' }}
          />
        </div>
      ),
  },
  ];
   

// Implement the logic to clone the form data
const handleCloneForm = async (form) => {
  try {
    const clonedForm = { 
      ...form, 
      id: Date.now().toString(), 
      title: `${form.title} (Clone)`,
      lastSubmitted: null // Set the lastSubmitted field to null or an empty string
    };
    const response = await axios.post(
      `${URL_SurveyFormAdminAPIV2}/dev/survey-formV2`,
      clonedForm
    );
    console.log("Cloned form response:", response.data);
    setSurveyData([...surveyData, clonedForm]);
    messageApi.open({
      key,
      type: "success",
      content: "Form cloned successfully!",
      duration: 2,
    });
  } catch (error) {
    console.error("Error cloning form:", error);
    messageApi.open({
      key,
      type: "error",
      content: "Failed to clone form.",
      duration: 2,
    });
  }
};

const handleEditForm = (record) => {
  
  console.log("Full record in handleEditForm:", record);
  
  if (!record || record.length === 0) {
    console.error("No record data available");
    return;
  }
  
  const formRecord = record[0] || record;
  
  if (!formRecord.form_schema) {
    console.error("form_schema is missing from the record", formRecord);
    return;
  }

  const formData = {
    formTitle: formRecord.title,
    form_schema: formRecord.form_schema.map((item) => ({
      field_type: item.field_type,
      label: item.label,
      placeholder: item.placeholder || '',
      required: item.required,
      options: item.options || [],
    })),
  };

  console.log("Prepared formData for navigation:", formData);
  navigate('/form-builder', { state: formData });
};


const handleDeleteClick = async (id) => {
  setLoading(true);
  try {
    console.log("deleteFormField id:", id);
   
    // Define the payload
    const payload = {
      form_id: id,
    };

    // Make the DELETE request
    const response = await fetch("https://04g4bjuen6.execute-api.us-east-2.amazonaws.com/dev/delete-form", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      alert("Form deleted successfully!");

      // Update state to remove the deleted form
      setForms((prevForms) => prevForms.filter((form) => form.id !== formId));
    } else {
      alert("Failed to delete the form.");
    }
  } catch (error) {
    console.error("Error deleting form:", error);
    alert("An error occurred while deleting the form.");
  } finally {
    setLoading(false); // Hide loader
  }
};



  const antIcon = (
    <>
      <LoadingOutlined style={{ fontSize: 24 }} spin />
    </>
  );
  const copyLinkToClipboard = () => {
    // inputRef.current.select();
    openMessage();
    navigator.clipboard.writeText(formURL);
  };

  const [shareForm] = Form.useForm();
  shareForm.setFieldsValue({
    title: formTitle,
  });

  const [isModalOpenForm, setIsModalOpenForm] = useState(false);

  const showModalForm = () => {
    setIsModalOpenForm(!isModalOpenForm);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleViewClick = (title) => {
    console.log("title:", title);
    setSelectedForm(title); // Set the selected form data
    setFormTitle(title); // Set the form title in the state
    shareForm.setFieldsValue({ title: title }); // Set the form title in the shareForm state
    showModal(); // Open the modal
  };

  useEffect(() => {
    if (formTitle) {
      shareForm.setFieldsValue({ title: formTitle });
    }
  }, [formTitle, shareForm]);

//   const handleOk = async (values) => {
//     setModalLoading(true);
//     console.log("values received:", values);
   
//     const { title, emails, content } = values;
   
//     console.log("Title:", title);
//     console.log("Emails:", emails);
//     console.log("Content:", content);
   
//     let scheduledTime = null;

//     if (selectedDate && selectedTime) {
//       if (!selectedTime.isValid()) {
//         console.error("Invalid selected time!");
//         messageApi.open({
//           key,
//           type: "error",
//           content: "Invalid time selected! Please select a valid time.",
//           duration: 2,
//         });
//         setModalLoading(false);
//         return;
//       }

//       // Clone the date and set time
//       const combinedDateTime = selectedDate.clone().set({
//         hour: selectedTime.hour(),
//         minute: selectedTime.minute(),
//         second: 0
//       });

//       console.log("Combined Date-Time:", combinedDateTime.format("YYYY-MM-DD HH:mm:ss"));

//       // Get timezone offset
//       const tzOffset = combinedDateTime.format('Z').replace(/(\d{2})(\d{2})/, '$1:$2');

//       // Format in required format with proper timezone
//       scheduledTime = combinedDateTime.format("YYYY-MM-DDTHH:mm:ss") + tzOffset;
//       console.log("Scheduled Time for payload:", scheduledTime);
//     }

//     let recipients = [];
//     if (Array.isArray(emails)) {
//       recipients = emails.map(({ name, email }) => ({
//         name: name || "",
//         email: email || "",
//       }));
//     } else if (typeof emails === 'string') {
//       const emailList = emails.split(',').map(email => email.trim());
//       console.log("Email list after splitting:", emailList);
   
//       recipients = emailList.map(email => ({
//         name: "",
//         email: email,
//       }));
//     } else {
//       recipients = [{
//         name: "",
//         email: emails || "",
//       }];
//     }
   
//     console.log("Recipients:", recipients);
   
//     let htmlContent = content?.html || editorValue || "";
   
//     const surveyLink = "userApplicationLink"; // Replace this with actual dynamic link
   
//     htmlContent = htmlContent.replace(
//       /Survey Link/g,
//       `<a href="${surveyLink}" target="_blank">Survey Link</a>`
//     );
   
//     const contentData = content || {
//       font: "dmsans",
//       size: "10px",
//       bold: true,
//       italic: true,
//       underline: true,
//       strike: false,
//       align: "left",
//       list: false,
//       bullet: false,
//       clean: false,
//       html: htmlContent,
//     };
   
//     console.log("Final Content:", contentData);
   
//     const payload = {
//       scheduledTime: scheduledTime,
//       form_id: selectedValue,
//       recipients,
//       content: contentData,
//     };
   
//     console.log("Payload being sent:", JSON.stringify(payload, null, 2));
   
//     if (selectedDate || selectedTime) {
//       if (!scheduledTime) {
//         messageApi.open({
//           key,
//           type: "warning",
//           content: "Please select both date and time for scheduling!",
//           duration: 2,
//         });
//         setModalLoading(false);
//         return;
//       }
//     }
   
//     try {
//       const response = await axios.post(
//         `${URL_SurveyBroadcastAPIV2}/dev/send-surveyV2`,
//         payload
//       );
   
//       console.log("Response:", response);
//       setEmails([]);
//       messageApi.open({
//         key,
//         type: "success",
//         content: scheduledTime ? "Email scheduled successfully!" : "Email sent immediately!",
//         duration: 2,
//       });
//     } catch (error) {
//       console.error("Error sending survey:", error);
//       messageApi.open({
//         key,
//         type: "error",
//         content: "Something went wrong!",
//         duration: 2,
//       });
//     }
   
//     showModal();
//     setModalLoading(false);
// };
 

  const handleCancelForm = () => {
    setIsModalOpenForm(!isModalOpenForm);
  };

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Add your email sending logic here, e.g., using a library like `react-emailjs`
  //   // console.log(`Sending email to: ${to}`);
  //   // console.log(`Subject: ${subject}`);
  //   // console.log(`Message: ${message}`);
  //   // axios
  //   //   .post(
  //   //     "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/send-survey",
  //   //     { title: subject, email: [to] }
  //   //   )
  //   //   .then((res) => {
  //   //     console.log(res);
  //   //   })
  //   //   .catch((err) => console.log(err));
  // };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = (e) => {
    e.preventDefault();
    if (
      inputValue &&
      validateEmail(inputValue) &&
      !emails.some((email) => email.email === inputValue)
    ) {
      setEmails([...emails, { name: "", email: inputValue }]);
      setInputValue("");
      shareForm.setFieldsValue({
        emails: [...emails, { name: "", email: inputValue }], // Update form field value
      });
    } else {
      messageApi.open({
        type: "error",
        content: "Invalid email or email already exists",
        duration: 2,
      });
    }
  };

  const handleRemoveEmail = (removedEmail) => {
    setEmails(emails.filter((email) => email !== removedEmail));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFileUploadForEmails = (file) => {
     const reader = new FileReader();
     reader.onload = (e) => {
       const data = new Uint8Array(e.target.result);
       const workbook = XLSX.read(data, { type: "array" });
       const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
       const emailsFromFile = XLSX.utils
         .sheet_to_json(firstSheet, { header: 1 })
         .filter((row) => validateEmail(row[1])) // Assuming email is in the second column
         .map((row) => ({ name: row[0], email: row[1] })); // Assuming name is in the first column
         const newEmails = [...emails, ...emailsFromFile];
         setEmails(newEmails);
         shareForm.setFieldsValue({
           emails: newEmails,
         });
       };
     reader.readAsArrayBuffer(file);
     return false; // Prevent upload
   };

  async function fetchSurveyForm() {
    try {
      const response = await axios.get(
        `${URL_SurveyFormAdminAPIV2}/dev/survey-formV2?id=null`
      );
      console.log(
        "response data*********************************************",
        response.data
      );
      setSurveyData(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching survey form:", error);
      throw error; // Re-throw the error to handle it appropriately in the calling component
    }
  }

  useEffect(() => {
    fetchSurveyForm();

    // .catch(error => setError(error));
  }, []);

  function fun(selectedValue) {
    console.log("Hello, you selected:", selectedValue);
  }

  async function getFormDataByID(item) {
    try {
      const url = `${URL_SurveyFormAdminAPIV2}/dev/survey-formV2?id=${item}`;
      axios
        .get(url) // Use params object for GET requests
        .then((response) => {
          console.log(response.data[0].title);
          setForms(response.data);
          setFormTitle(response.data[0].title);
          setShareDisable(true);
          shareForm.setFieldsValue({
            title: response.data[0].title,
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching survey form:", error);
      throw error; // Re-throw the error to handle it appropriately in the calling component
    }
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    const loadForms = async () => {
      try {
        const item = localStorage.getItem("form-builder");
        setForms(item ? [JSON.parse(item)] : null);
      } catch (error) {}
    };

    // loadForms();
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  

  const renderFormField = (field) => {
    const commonStyles = {
      width: "100%",
      padding: "6px",
      margin: "6px 0",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "12px",
      color: "#495057",
    };

    switch (field.field_type) {
      case "text":
        return (
          <Form.Item label={field.label} name={field.label}>
            <Input
              placeholder={field.placeholder}
              style={{
                color: "#ffffff",
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                cursor: "not-allowed",
                opacity: 1,
              }}
            />
          </Form.Item>
        );
      case "email":
        return (
          <Form.Item name={field.label} label={field.label}>
            <Input
              placeholder={field.placeholder}
              style={{
                color: "#ffffff",
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                cursor: "not-allowed",
                opacity: 1,
              }}
            />
          </Form.Item>
        );
      case "number":
        return (
          <Form.Item label={field.label} name={field.label}>
            <InputNumber
              placeholder={field.placeholder}
              style={{
                width: "100%",
                color: "#ffffff",
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                cursor: "not-allowed",
                opacity: 1,
              }}
            />
          </Form.Item>
        );
      case "password":
        return (
          <Form.Item label={field.label} name={field.label}>
            <Input.Password placeholder={field.placeholder} />
          </Form.Item>
        );
      case "checkbox":
        return (
          <Form.Item label={field.label}>
            <Checkbox.Group>
              {field.options.map((option, index) => (
                <Checkbox value={option}>{option}</Checkbox>
                // <Radio value={option}>{option} </Radio>
              ))}
            </Checkbox.Group>
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item label={field.label}>
            <Radio.Group>
              {field.options.map((option, index) => (
                <Radio value={option}>{option} </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      case "dropdown":
        return (
          <Form.Item label={field.label} name={field.label}>
            <Select
              placeholder={field.placeholder}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  ?.toLowerCase()
                  .localeCompare((optionB?.label ?? "")?.toLowerCase())
              }
              options={field.options.map((option) => ({
                value: option,
                label: option,
              }))}
              style={{
                color: "#333", // Dark text for better readability
                backgroundColor: "#ffffff", // White background
                borderColor: "#ddd", // Light border
                borderRadius: "8px", // Rounded corners
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
                cursor: "not-allowed", // Not allowed cursor
                opacity: 1, // Full opacity
              }}
              dropdownStyle={{
                backgroundColor: "#ffffff", // White dropdown background
              }}
            />
          </Form.Item>
        );
      case "date":
        return (
          <Form.Item label={field.label} name={field.label}>
            <DatePicker />
          </Form.Item>
        );
      case "textarea":
        return (
          <Form.Item label={field.label}>
            <TextArea rows={4} placeholder={field.placeholder} />
          </Form.Item>
        );
      case "file":
        return (
          <Form.Item
            label={field.label}
            name={field.label}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload action="/upload.do" listType="picture-card" maxCount={1}>
              <button
                style={{
                  border: "2px solid rgb(205 187 238)", // Solid border in purple
                  backgroundColor: "#ffffff", // White background
                  borderRadius: "8px", // Rounded corners
                  padding: "20px", // Padding for better spacing
                  cursor: "pointer", // Pointer cursor for interactivity
                  transition: "background-color 0.3s, border-color 0.3s", // Smooth transition
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                }}
                type="button"
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#f0f0f0"; // Light gray on hover
                  e.currentTarget.style.borderColor = "#5a34a1"; // Darken border on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#ffffff"; // Reset background
                  e.currentTarget.style.borderColor = "rgb(55 23 255)"; // Reset border
                }}
              >
                <PlusOutlined
                  style={{ fontSize: "24px", color: "rgb(55 23 255)" }}
                />
                <div
                  style={{
                    marginTop: 5,
                    color: "rgb(55 23 255)",
                    fontWeight: "500",
                  }}
                >
                  Upload
                </div>
              </button>
            </Upload>
          </Form.Item>
        );
      case "button":
        return (
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "rgb(55 23 255)", // Purple background
                borderColor: "rgb(55 23 255)", // Purple border
                color: "#ffffff", // White text color
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Optional shadow for depth
                textTransform: "capitalize",
              }}
            >
              {field.placeholder}
            </Button>
          </Form.Item>
        );
      default:
        return <div>Unknown Form Item</div>;
    }
  };

  // const generateEmbedCode = (slug) => {
  //   const embedUrl = `${API_URL}/api/V2/forms/${slug}`;
  //   return `<iframe src="${embedUrl}" width="100%" height="500px" frameborder="0"></iframe>`;
  // };

  const handleShareClick = (slug) => {
    console.log("slug:", slug);
    // const embedCode = generateEmbedCode(slug);
    // navigator.clipboard.writeText(embedCode);
    alert("Embed code copied to clipboard!");
  };

  const generateHtmlString = (formFields) => {
    return formFields
      .map((field) => {
        switch (field.field_type) {
          // {
          //   case "text":
          //   case "email":
          //   case "number":
          //   case "password":
          //     return `<input type="${field.field_type}" placeholder="${field.placeholder}" disabled />`;
          //   case "checkbox":
          //   case "radio":
          //     return `<label><input type="${field.field_type}" disabled /> ${field.label}</label>`;
          //   case "dropdown":
          //     return `<select disabled>${field.options
          //       .map((option) => `<option>${option}</option>`)
          //       .join("")}</select>`;
          //   case "date":
          //     return `<input type="date" disabled />`;
          //   case "textarea":
          //     return `<textarea placeholder="${field.placeholder}" disabled></textarea>`;
          //   case "file":
          //     return `<input type="file" disabled />`;
          //   default:
          //     return `<div>Unknown field type</div>`;
          // }
          case "text":
            return `<Form.Item label="${field.label}" name="${field.label}">
                <Input placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "email":
            return `
              <Form.Item name="${field.label}" label="${field.label}">
                <Input placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "number":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <InputNumber
                  placeholder="${field.placeholder}"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>`;
          case "password":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <Input.Password placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "checkbox":
            return `
                <Form.Item label="${field.label}">
                    <Checkbox.Group>
                      ${field.options
                        .map(
                          (option, index) =>
                            `<Checkbox value=${option}>${option}</Checkbox>`
                        )
                        .join("")}
                    </Checkbox.Group>
                  </Form.Item>
          `;
          case "radio":
            return `
              <Form.Item label="${field.label}">
                <Radio.Group>
                  ${field.options
                    .map(
                      (option, index) =>
                        ` <Radio value=${option}>${option} </Radio>`
                    )
                    .join("")}
                </Radio.Group>
              </Form.Item>`;
          case "dropdown":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <Select
                  placeholder="${field.placeholder}"
                  filterSort=${(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                      .join("")}
                  options=${field.options
                    .map((option, index) => ({
                      value: option,
                      label: option,
                    }))
                    .join("")}
                />
              </Form.Item>`;
          case "date":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <DatePicker />
              </Form.Item>`;
          case "textarea":
            return `
              <Form.Item label="${field.label}">
                <TextArea rows={4} placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "file":
            // return <input type="file" disabled={true} />;
            return `
              <Form.Item
                label="${field.label}"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload action="/upload.do" listType="picture-card" maxCount={1}>
                  <button
                    style={{
                      border: 0,
                      background: "none",
                    }}
                    type="button"
                  >
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 5,
                      }}
                    >
                      Upload
                    </div>
                  </button>
                </Upload>
              </Form.Item>`;
          case "button":
            return `
                  <Form.Item
                    wrapperCol={{
                      offset: 0,  // Aligns the button to the left
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ textTransform: "capitalize" }}
                    >
                      ${capitalizeFirstLetter(field.placeholder)}
                    </Button>
                  </Form.Item>
                `;

          default:
            return <div>Unknown Form Item</div>;
        }
      })
      .join("");
  };

  const capitalizeFirstLetter = (str) => {
    // Check if the input is a string and not empty
    if (typeof str !== "string" || str.length === 0) return str;

    // Capitalize the first letter and concatenate it with the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleCopyHtml = (formFields) => {
    let htmlString = generateHtmlString(formFields);
    const prefix = `<Form
    name="basic"
    layout="vertical"
    onFinish={onFinish}
    labelCol={{
          span: 2,
        }}
  >`;

    const suffix = `</Form>`;
    htmlString = prefix + htmlString + suffix;
    console.log("htmlString:", htmlString);
    navigator.clipboard.writeText(htmlString).then(() => {
      alert("HTML copied to clipboard!");
    });
  };

  const handleChange = (value) => {
    console.log("valuev", value);
    getFormDataByID(value);
    setSelectedValue(value);
    setSelectedForm(surveyData.find((item) => item.id === value)); // Set the selected form
  };
  //yha pe view form heading ki css hai

  const PageContainer = styled.div`
    display: flex;
    background-color: white;
    min-height: calc(100vh - 64px);
    padding: 24px;
  `;

  const MainContent = styled.div`
    flex-grow: 1;
    background: white;
    border-radius: 12px;
    border: 1px solid #e6e8f0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  `;

  const HeaderSection = styled.div`
    padding: 24px;
    border-bottom: 1px solid #e6e8f0;
  `;

  const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
    }
  `;

  const Title = styled.h3`
    font-size: 24px;
    font-weight: 600;
    color: #2b2e48;
    margin: 0;
  `;

  const ActionButtons = styled.div`
    display: flex;
    gap: 12px;

    @media (max-width: 768px) {
      justify-content: stretch;
    }
  `;

  const StyledButton = styled.button`
    padding: 12px 20px;
    background-color: ${(props) =>
      props.variant === "primary" ? "#2B2E48" : "#28a745"};
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.9;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  `;

  // const FormContainer = styled.div`
  //   padding: 24px;
  //   max-width: 600px;
  //   margin: 0 auto;
  // `;

  const FormField = styled.div`
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px solid #e9ecef;
  `;

// Handler for date picker (with renamed function)
const onDateSelect = (date) => {
  console.log("Selected date:", date);
  setSelectedDate(date);
};

const handleTimeChange = (time) => {
  if (time && time.isValid()) {
    console.log("Time Selected:", time.format("HH:mm"));
    setSelectedTime(time); // Update selectedTime state
  } else {
    console.log("No valid time selected");
    setSelectedTime(null);
  }
};


const getScheduledDateTime = () => {
  if (!selectedDate || !selectedTime || !selectedTime.isValid()) {
    console.log(" Error: Missing or Invalid Date/Time");
    return null;
  }

  // Log all relevant information for debugging
  console.log("selectedDate year:", selectedDate.year());
  console.log("selectedDate month:", selectedDate.month());
  console.log("selectedDate day:", selectedDate.date());
  console.log("selectedTime hour:", selectedTime.hour());
  console.log("selectedTime minute:", selectedTime.minute());

  // Create a plain string in exactly the format the backend expects
  const year = selectedDate.year();
  const month = (selectedDate.month() + 1).toString().padStart(2, '0'); // months are 0-indexed in moment
  const day = selectedDate.date().toString().padStart(2, '0');
  const hour = selectedTime.hour().toString().padStart(2, '0');
  const minute = selectedTime.minute().toString().padStart(2, '0');
  const timezone = selectedDate.format("Z"); // Gets timezone like "+05:30"

  // Build the exact string format needed
  const formattedDateTime = `${year}-${month}-${day}T${hour}:${minute}:00${timezone}`;
  
  console.log(" Final Scheduled Time for Payload:", formattedDateTime);
  return formattedDateTime;
};

const handleOk = async (values) => {
  setModalLoading(true);
  console.log("values received:", values);

  const { title, emails, content } = values;

  console.log("Title:", title);
  console.log("Emails:", emails);
  console.log("Content:", content);

  // Get updated scheduled time dynamically
  let scheduledTime = null;
 
  if (selectedDate && selectedTime && selectedTime.isValid()) {
    scheduledTime = getScheduledDateTime();

  if (!scheduledTime) {
    console.log("Either selectedDate or selectedTime is missing or invalid.");
    messageApi.open({
      key,
      type: "warning",
      content: "Please select both date and time for scheduling!",
      duration: 2,
    });
    setModalLoading(false);
    return;
  }
}

  let recipients = [];
  if (Array.isArray(emails)) {
    recipients = emails.map(({ name, email }) => ({
      name: name || "",
      email: email || "",
    }));
  } else if (typeof emails === "string") {
    const emailList = emails.split(",").map((email) => email.trim());
    console.log("Email list after splitting:", emailList);

    recipients = emailList.map((email) => ({
      name: "",
      email: email,
    }));
  } else {
    recipients = [
      {
        name: "",
        email: emails || "",
      },
    ];
  }

  console.log("Recipients:", recipients);

  let htmlContent = content?.html || editorValue || "";

  const surveyLink = "userApplicationLink"; // Replace this with actual dynamic link

  htmlContent = htmlContent.replace(
    /Survey Link/g,
    `<a href="${surveyLink}" target="_blank">Survey Link</a>`
  );

  const contentData = content || {
    font: "dmsans",
    size: "12px",
    bold: false,
    italic: false,
    underline: false,
    strike: false,
    align: "left",
    list: false,
    bullet: false,
    clean: false,
    html: htmlContent,
  };

  console.log("Final Content:", contentData);

  const payload = {
    scheduledTime: scheduledTime,  // ✅ Now this always reflects the latest time
    form_id: selectedValue,
    recipients,
    content: contentData,
  };

  console.log("Payload being sent:", JSON.stringify(payload, null, 2));

  try {
    const response = await axios.post(
      `${URL_SurveyBroadcastAPIV2}/dev/send-surveyV2`,
      payload
    );

    console.log("✅ Response:", response);
    setEmails([]);
    messageApi.open({
      key,
      type: "success",
      content: scheduledTime
        ? "Email scheduled successfully!"
        : "Email successfully sent !",
      duration: 2,
    });
  } catch (error) {
    console.error("Error sending survey:", error);
    messageApi.open({
      key,
      type: "error",
      content: "Something went wrong!",
      duration: 2,
    });
  }

  showModal();
  setModalLoading(false);
};


  return (
    <>
      <BreadCrumbs location={props.location} />
      {loading && <div className="loader">Deleting...</div>}
      <div
        style={{
          display: "flex",
          // backgroundColor: "#f5f5f5", // A light background like Google Forms
          justifyContent: "center", // Center the form horizontally
          height: "92vh", // Full viewport height
          position: "fixed",
          width: "-webkit-fill-available",
          paddingBottom: "100px",
        }}
      >
        {contextHolder}
        <div
          style={{
            padding: "10px", // Extra padding for a spacious feel
            flexGrow: 1,
            maxWidth: "1160px", // Larger width for readability
            margin: "16px",
            // border: "1px solid #e0e0e0", // Lighter border color
            borderRadius: "12px", // Rounded corners for a modern look
            // backgroundColor: "#ffffff",
            // boxShadow: "0 6px 25px rgba(0, 0, 0, 0.1)", // Softer box-shadow for elegance
            overflow: "auto", // Enable scrolling inside this container
            maxHeight: "90vh", // Limit height for scrolling
          }}
        >
          {/* Share Form Modal */}
          <Modal
            title="Share Form"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={650}
            style={{ fontFamily: "Calibri, sans-serif", fontSize: "10px" }}
          >
            <Spin
              indicator={antIcon}
              spinning={modalLoading}
              tip="Sending emails..."
            >
              <Form
                form={shareForm}
                name="basic"
                style={{ maxWidth: "100%" }}
                onFinish={handleOk}
              >
                <Form.Item
                  label={
                    <span
                      style={{
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Form Title
                    </span>
                  }
                  name="title"
                  style={{
                    fontFamily: "Calibri, sans-serif",
                    fontSize: "12px",
                  }}
                >
                  <Input
                    style={{
                      fontFamily: "Calibri, sans-serif",
                      fontSize: "12px",
                    }}
                  />
                </Form.Item>
 
                <Form.Item
                  label={
                    <span
                      style={{
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#black",
                      }}
                    >
                      Email
                    </span>
                  }
                  name="emails"
                  rules={[
                    { required: false, message: "Please input your email!" },
                  ]}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Input
                      type="text"
                      placeholder="Enter email and press Enter"
                      value={inputValue}
                      onChange={handleInputChange}
                      onPressEnter={(e) => handleInputConfirm(e)}
                      style={{
                        width: "calc(129% - 100px)",
                        marginRight: "10px",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12px",
                      }}
                    />
                    <Upload
                      beforeUpload={handleFileUploadForEmails}
                      showUploadList={false}
                    >
<div style={{ position: "relative", display: "inline-block" }}>
      <Button
        style={{
          height: "26px",
          backgroundColor: "rgb(23, 118, 236)",
          color: "#ffffff",
          border: "1px solid #ccc",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <UploadOutlined />
      </Button>
      {hovered && (
        <span style={{
          position: "absolute",
          top: "30px", // Adjust as needed
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#ffffff",
          padding: "2px 5px",
          borderRadius: "4px",
          boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
          fontSize: "12px",
          color: "#000",
          whiteSpace: "nowrap",
        }}>
          Upload File
        </span>
      )}
    </div>
                    </Upload>
                  </div>
                </Form.Item>
 
                {emails.map((recipient) => (
                  <Tag
                    key={recipient.email}
                    closable
                    onClose={() => handleRemoveEmail(recipient.email)}
                    style={{
                      height: "25px",
                      margin: "0 0 5px 0",
                      backgroundColor: "#f1f3f4",
                      color: "#5f6368",
                    }}
                  >
                    {recipient.email}
                  </Tag>
                ))}
 
                <div style={{ marginTop: "20px" }}>
                <div
  style={{
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  }}
>
  <strong style={{ marginRight: "10px" }}>
    Schedule at:
  </strong>
  <DatePicker
    format="DD/MM/YYYY"
    onChange={onDateSelect}
    style={{ marginRight: "8px" }}
  />
  <TimePicker
    format="HH:mm"
    onChange={handleTimeChange}
  />
</div>
 
                  <ReactQuill
                    value={editorValue}
                    onChange={setEditorValue}
                    modules={{
                      toolbar: [
                        [{ font: [] }],
                        [{ size: [] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ align: [] }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "header",
                      "font",
                      "size",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "align",
                      "list",
                      "bullet",
                      "clean",
                    ]}
                    style={{
                      minHeight: "150px",
                      marginBottom: "10px",
                      fontFamily: "Calibri, sans-serif", // Set Calibri font for editor content
                    }}
                  />
                  <style>
                    {`
      .ql-container {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px; /* Adjust padding for overall container */
        max-height: 200px; /* Set a max height for scrolling */
        overflow-y: auto; /* Enable vertical scrolling */
        position: relative; /* Position relative for absolute positioning */
      }
      .ql-editor {
        font-family: 'Calibri', sans-serif; /* Ensure editor content uses Calibri */
        padding: 0; /* Remove padding to allow cursor at the top */
        min-height: 150px; /* Maintain minimum height */
      }
      .ql-editor.ql-blank::before {
        font-family: 'Calibri', sans-serif; /* Ensure placeholder uses Calibri */
        font-style: normal; /* Remove italic style */
        color: #999; /* Placeholder color */
        content: 'Hello {name}\\A\\AThis is GeneralSurvey Form\\ASurvey Link\\A\\AThanks\\AHR Team!'; /* Custom placeholder text */
        white-space: pre-line; /* Preserve line breaks */
        position: absolute; /* Position it absolutely */
        top: 10px; /* Position at the top */
        left: 10px; /* Align with padding */
        pointer-events: none; /* Allow clicks to go through to the editor */
      }
    `}
                  </style>
                </div>
 
                {/* Action Buttons (Share and Cancel) */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    key="back"
                    onClick={handleCancel}
                    // style={{
                    //   width: "19%",
                    //   height: "40px",
                    //   fontSize: "12px",
                    //   backgroundColor: "#f1f3f4",
                    //   borderRadius: "5px",
                    // }}
                  >
                    Cancel
                  </Button>
 
                  <Button
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    // style={{
                    //   width: "16%",
                    //   height: "40px",
                    //   fontSize: "12px",
                    //   backgroundColor: "rgb(232 128 23 / 56%)",
                    //   color: "white",
                    //   border: "none",
                    //   borderRadius: "30px",
                    //   cursor: "pointer",
                    // }}
                  >
                    {/* <FontAwesomeIcon
                      icon={faShare}
                      style={{ marginRight: "8px" }}
                    /> */}
                    Share
                  </Button>
                </div>
              </Form>
            </Spin>
          </Modal>
 

          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <Input
          placeholder="Search by form name"
          value={searchInput}
          onChange={handleSearchInputChange}
          style={{ width: "300px" }}
          suffix={<SearchOutlined />}
        />
        <RangePicker onChange={handleDateChange} />
      </div>
           
          {/* {filteredSurveyData.length > 0 ? (
            <table
              style={{
                width: "100%",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "#ffffff",
                    color: "black",
                    fontWeight: "600",
                    textAlign: "centre",
                  }}
                >
                  <th style={{ padding: "10px", border: "1px solid #e0e0e0" }}>
                    Title
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #e0e0e0" }}>
                    Created At
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #e0e0e0" }}>
                    Last Submitted
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #e0e0e0" }}>
                    View
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #e0e0e0" }}>
                    Share
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredSurveyData.map((item) => (
                  <tr key={item.id}>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        color: "#5f6368",
                      }}
                    >
                      {item.title}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        color: "#5f6368",
                      }}
                    >
                      {item.createdAt}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        color: "#5f6368",
                      }}
                    >
                      {item.lastSubmitted}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        color: "#5f6368",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleChange(item.id);
                          showModalForm();
                        }}
                      >
                        View
                      </Button>
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        color: "#5f6368",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleChange(item.id);
                          handleViewClick(item.title);
                        }}
                      >
                        Share
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No results found.</p> 
          )} */}
          {/* <hr style={{ borderTop: "1px solid #e0e0e0" }} /> */}

          {filteredSurveyData.length > 0 ? (
        <Table
          columns={columns}
          dataSource={filteredSurveyData.map((item) => ({
            ...item,
            key: item.id,
          }))}
          pagination={{ pageSize: 10 }}
        />
      ) : (
        <p>No results found.</p>
      )}

          <Modal
            // title="Share Form"
            open={isModalOpenForm}
            onCancel={handleCancelForm}
            footer={null}
            style={{
              fontFamily: "Roboto, sans-serif",
            }}
          >
            <Form
              layout="vertical"
              disabled={true}
              style={{
                // width: "100%",
                margin: "20px auto",
                backgroundColor: "#ffffff",
                borderRadius: "16px", // More rounded edges
                boxShadow: "0 6px 20px rgba(0, 0, 0, 0.12)", // Soft shadow
                overflowY: "auto",
                height: "60vh",
              }}
            >
              {forms?.map((form) => (
                <div key={form.id} style={{ marginBottom: "30px" }}>
                  <div
                    style={{
                      padding: "6px",
                      // backgroundColor: "#5046e5d9",
                      textAlign: "center",
                      borderRadius: "5px",
                      color: "black",
                      fontWeight: "600",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Shadow effect
                    }}
                  >
                    {form.title}
                  </div>
                  <div style={{ padding: "20px" }}>
                    {form.form_schema.map((field) => (
                      <div key={field.id} style={{ marginBottom: "20px" }}>
                        <label
                          style={{
                            display: "block",
                            marginBottom: "8px",
                            color: "#5f6368", // Dark text for labels
                            fontWeight: "600",
                            fontSize: "8px",
                          }}
                        >
                          {/* {field.label}  */}
                        </label>
                        {renderFormField(field, {
                          placeholderStyle: { color: "black" },
                        })}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ViewForms;