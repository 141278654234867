export const routes = {
  HOME: "/",
  ADMIN_DASHBOARD: "/dashboard",
  FORM_BUILDER: "/form-builder",
  VIEW_FORM: "/view-forms",
  SURVEY_ANALYSER: "/survey-analyser",
  VIEW_ANALYSER: "/survey-analyser/view-analyser",
  VIEW_RESPONSE: "/survey-analyser/view-response",
};
export const brand = {
  NAME: "Survey Analyser",
};

export const URL_SurveyBroadcastAPIV2="https://a9aikwz2rg.execute-api.us-east-2.amazonaws.com";
export const URL_SurveyFormAdminAPIV2="https://04g4bjuen6.execute-api.us-east-2.amazonaws.com";
