import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./viewResponse.css";
// import background from "../../assests/img/background.png"
import BreadCrumbs from "../BreadCrumbs";
import { URL_SurveyBroadcastAPIV2, URL_SurveyFormAdminAPIV2 } from "../../util/constants";

function SurveyResponse(props) {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [title, setTitle] = useState("");
  const location = useLocation();
  const style = {
    // backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    width: "100vw",
  };

  useEffect(() => {
    setUserId(location.state.email);
    console.log("location.state.submitedBy:", location.state.submitedBy);
    console.log("location.state.email:", location.state.email);
  }, []);

  useEffect(() => {
    const apiUrl =
      `${URL_SurveyBroadcastAPIV2}/dev/survey-responseV2/get-response-to-adminV2`
    const payload = {
      id: location.state.email,
    };

    console.log(
      location.state.email,
      "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&"
    );

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        setResponse(data.body.formResponse);
        setTitle(data.body.title);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const renderField = (question, entry) => {
    if (entry.type === "button") {
      return null;
    }
    switch (entry.type) {
      case "text":
      case "number":
        return (
          <input
            type={entry.type}
            className="response-input"
            value={entry.value ?? ""}
            readOnly
          />
        );

      case "textarea":
        return (
          <textarea
            className="response-textarea"
            value={entry.value ?? ""}
            readOnly
          />
        );

      case "dropdown":
        return (
          <select
            className="response-select"
            value={entry.value ?? ""}
            disabled
          >
            <option>{entry.value}</option>
          </select>
        );

      case "button":
        return (
          <button type="button" className="response-button" disabled>
            {entry.value}
          </button>
        );

      case "file":
        return renderFile(entry.value);

      default:
        return (
          <input
            type="text"
            className="response-input"
            value={entry.value ?? ""}
            readOnly
          />
        );
    }
  };

  const renderFile = (fileData) => {
    if (!fileData || !fileData.fileName || !fileData.link) return null;

    console.log("Rendering file:", fileData.fileName);

    // Render image file
    if (fileData.fileName.match(/\.(jpg|jpeg|png|jfif)$/i)) {
      return (
        <div className="file-container">
          <img src={fileData.link} alt="Uploaded File" className="file-media" />
          <p className="file-name">{fileData.fileName}</p>
        </div>
      );
    }

    // Render video file
    if (fileData.fileName.match(/\.mp4$/i)) {
      return (
        <div className="file-container">
          <video controls className="file-media">
            <source src={fileData.link} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p className="file-name">{fileData.fileName}</p>
        </div>
      );
    }

    // Render audio file
    if (fileData.fileName.match(/\.(wav|mp3)$/i)) {
      return (
        <div className="file-container">
          <audio
            controls
            className="file-audio"
            onError={() => alert("Failed to load audio file.")}
          >
            <source
              src={fileData.link}
              type={
                fileData.fileName.endsWith(".wav") ? "audio/wav" : "audio/mp3"
              }
            />
            Your browser does not support the audio element.
          </audio>
          <p className="file-name">{fileData.fileName}</p>
        </div>
      );
    }

    // Render other file types
    return (
      <p className="file-link">
        File:{" "}
        <a href={fileData.link} target="_blank" rel="noopener noreferrer">
          {fileData.fileName}
        </a>
      </p>
    );
  };

  // if (loading) {
  //   return <div className="loading" >Loading...</div>;
  // }

  console.log(response, "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");

  if (!response) {
    return (
      <div className="no-data">
        No response data available or incorrect structure.
      </div>
    );
  }

  return (
    <div>
      <div style={{ marginBottom: "24px" }}>
        <BreadCrumbs location={props.location} />
      </div>
      <div className="survey-container">
        <h1 className="survey-title">{title || "Survey Response"}</h1>
        <form className="survey-form">
          {Object.entries(response).map(([question, entry]) => {
            if (entry.type === "button") {
              return null; // Skip rendering both field and label for button type
            }
            return (
              <div key={question} className="question-container">
                <label className="question-label">{question}</label>
                {entry.type === "file" ? (
                  <div className="file-response">
                    {Array.isArray(entry.value) ? (
                      entry.value.map((fileData, index) => (
                        <div key={index} className="file-wrapper">
                          {renderFile(fileData)}
                        </div>
                      ))
                    ) : (
                      <div className="file-wrapper">
                        {renderFile(entry.value)}
                      </div>
                    )}
                  </div>
                ) : (
                  renderField(question, entry)
                )}
              </div>
            );
          })}
        </form>
      </div>
    </div>
  );
}

export default SurveyResponse;
